export class AppConfig {
  production: boolean;
  apiUrl: string;
  baseHref: string;
  issuerUrl: string;
  postLogoutRedirectUri: string;
  clientId: string;
  envName: string;
}

const appConfigProduction: AppConfig = {
  production: true,

  apiUrl: "https://ost.xp-solutions.net/api",
  baseHref: "https://ost.xp-solutions.net",
  issuerUrl: "https://ost.xp-solutions.net/api",
  postLogoutRedirectUri: "https://ost.xp-solutions.net",
  clientId: "eba89390-cdd3-49f3-8439-e78be00d401c",
  envName: "production",
};


export const appConfig: AppConfig = appConfigProduction;
// {
//   production: false,
//   apiUrl: "https://localhost:5001",
//   baseHref: "https://localhost:3000/index.html",
//   issuerUrl: "https://localhost:5001",
//   postLogoutRedirectUri: "https://localhost:3000/index.html",
//   clientId: "word.client",
//   envName: "develop",
// };
// {
//   production: false,
//   apiUrl: "https://localhost/api/",
//   baseHref: "https://localhost/office/",
//   issuerUrl: "https://localhost/api/",
//   postLogoutRedirectUri: "https://localhost/api/",
//   clientId: "word.client",
//   envName: "develop",
// };
