import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import * as XLSX from "ts-xlsx";
import { ExcelFile } from "../models/excel.file";
import { FileUpdateEvent, UpdateType } from "../models/file.update.event";
import { Marker } from "../models/marker";

@Injectable({
  providedIn: "root",
})
export class ExcelFileService {
  files: Array<ExcelFile> = new Array<ExcelFile>();
  fileUpdate = new Subject<FileUpdateEvent>();
  getFiles(): ExcelFile[] {
    return this.files;
  }

  findWorkbook(marker: Marker): XLSX.IWorkBook | undefined {
    if (marker === undefined) return undefined;
    for (let i = 0; i < this.files.length; i++) {
      if (
        this.files[i] !== undefined &&
        this.files[i].workbook !== undefined &&
        this.files[i].workbook.SheetNames.includes(marker.sheet) &&
        this.files[i].id === marker.path
      ) {
        return this.files[i].workbook;
      }
    }
    return undefined;
  }

  addFile(file: ExcelFile): void {
    const filenames = this.files.map((file) => file.id);
    if (!filenames.includes(file.id)) {
      this.files.push(file);
      this.fileUpdate.next(new FileUpdateEvent(UpdateType.add, file));
    }
  }

  removeFile(id: string): void {
    const result: ExcelFile[] = [];
    let removedFile: ExcelFile | undefined;
    for (let i = 0; i < this.files.length; i++) {
      if (this.files[i].id !== id) {
        result.push(this.files[i]);
      } else {
        removedFile = this.files[i];
      }
    }
    if (removedFile !== undefined) {
      this.files = result;
      this.fileUpdate.next(new FileUpdateEvent(UpdateType.remove, removedFile));
    }
  }
}
