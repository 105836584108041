import { Authenticator, DefaultEndpoints, Utilities } from "@microsoft/office-js-helpers";
import { UserDetail } from "../models/user.detail";
import axios from "axios";
import { AxiosResponse } from "axios";
import { appConfig } from "../models/app.config";
import { UserVerificationResponse } from "../models/user.verication.response";

export function authenticate(): Promise<UserDetail|void> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise<UserDetail|void>(async (resolve, reject) => {

    if(Authenticator.isAuthDialog()) {
      console.log("Auth in Dialog, reject");
      reject();
    }
    const authenticator = new Authenticator();
    authenticator.endpoints
                 .registerMicrosoftAuth(appConfig.clientId);

    await authenticator.authenticate(DefaultEndpoints.Microsoft).catch((error)=>{
      Utilities.log("Fehler bei der Authentifizierung", error);
      reject(error);
    });
    const msal = authenticator.tokens.get(DefaultEndpoints.Microsoft);
    if (!msal.access_token || msal.access_token === null) reject();
    const user = await fetchUserInfo(msal.access_token as string).catch((err: any) => {
      Utilities.log("Fehler bei der Authentifizierung", err);
      reject(err);
    });
    resolve(user);
    reject();
  })
}

function fetchUserInfo(accessToken: string): Promise<UserDetail> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise<UserDetail>(async (resolve, reject) => {
    const config = { 'Authorization': `Bearer ${accessToken}` };
    const response: AxiosResponse<any> | void = await axios.get<any>(`https://graph.microsoft.com/v1.0/me/`, {
        headers: config
    }).catch((err) => {
      console.log(err);
      reject(err);
    });
    if (response && response.status === 200 && response.data) {
      if ((response.data.mail === undefined || response.data.mail === null) && (response.data.userPrincipalName !== undefined)) {
        response.data.mail = response.data.userPrincipalName;
      }
      if (response.data.mail === undefined || response.data.mail === null) {
        console.log('unknown response format', response);
      }
      resolve(response.data);
    }
    reject();
  });
}

export function verifyUser(user: UserDetail): Promise<boolean> {
  //console.debug(user, accessToken);

  /*eslint-disable-next-line no-async-promise-executor*/
  return new Promise<boolean> (async (resolve) => {
      const result: void | AxiosResponse<UserVerificationResponse> = await axios.get<UserVerificationResponse>(`https://ost.xp-solutions.net/XPS.OST.Api.php?${user.mail}`).catch((err) => {
        console.log("Fehler bei der Benutzerverifizierung", err);
        resolve(false);
      });

      if (result && result.status === 200)
      {
        //console.log("VerificationResponse", result, user);
        resolve(result.data.email.toLowerCase() === user.mail.toLowerCase());
      }
      resolve(false);
    });
}


