<header #header style="position: fixed; background-color: lightgray; z-index: 10; width: 100%; padding-bottom: 15px;">
  <div style="display: flex;align-items: center;gap: 10px;">
    <img width="90" height="90" src="./assets/images/logo-filled.png" alt="ost-logo" title="OST" />
    <h1 style="margin-top: 10px">{{titleMessage}}</h1>
  </div>
  <div *ngIf="isVerified && displayName">
    <app-excel-file-component></app-excel-file-component>
  </div>
</header>

<main class="ms-welcome__main" [ngStyle]="{'padding-top': headerHeight + 'px'}">
<ng-container *ngIf="isVerified && displayName">
    <div >
      <!---->
      <div style="padding-top: 15px; background-color: lightgray; z-index: 10; width: 100%">
        <app-marker-component></app-marker-component>
      </div>
      <div>
        <app-buttons></app-buttons>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!isVerified">
      <div>
        {{helpText}}
      </div>
  </ng-container>
</main>

