/* eslint-disable no-async-promise-executor */
import { Dictionary } from "@microsoft/office-js-helpers";
import { TagData } from "../models/tagdata";
import { formatTableBorders } from "./helpers.format";

/* global Word, console */

export function insertContentControlTagAtCursor(tagData: TagData): Promise<Word.ContentControl> {
  return new Promise<Word.ContentControl>((resolve) => {
    Word.run(async (context) => {
      const cursor = context.document.getSelection();
      await context.sync();
      const contentControl: Word.ContentControl = cursor.insertContentControl();
      contentControl.track();
      contentControl.tag = tagData.tag;
      contentControl.title = tagData.tag;
      if (tagData.isText()) {
        await insertTextToContentControlOnContext(context, contentControl, tagData);
      } else {
        await insertTableToContentControlOnContext(context, contentControl, tagData);
      }
      await context.sync();
      //No onDelete event defined in api yet
      //const cCtrlRange: Word.Range = contentControl.getRange("Whole");
      //contentControl.onDeleted.add(() => console.log("cCtrolRange deleted"));
      //await context.sync();
      resolve(contentControl);
    });

  });
}

export function clearAndInsertContent(tagData: TagData): Promise<Word.ContentControl[]> {
  return new Promise<Word.ContentControl[]>((resolve) => {
    Word.run(async (context) => {
      const contentControlDict = await readContentControlsOnContext(context);
      const contentControls = contentControlDict.get(tagData.tag);
      if (!contentControls) {
        resolve([]);
      }
      for (let i = 0; i < contentControls.length; i++) {
        contentControls[i].load();
        await context.sync();
        contentControls[i].clear();
        contentControls[i].select("Select");

        await context.sync();
        if (tagData.isText()) {
          await insertTextToContentControlOnContext(context, contentControls[i], tagData);
        } else {
          await insertTableToContentControlOnContext(context, contentControls[i], tagData);
        }
      }
      resolve(contentControls);
    });
  });
}

function insertTableToContentControlOnContext(context: Word.RequestContext, contentControl: Word.ContentControl, tagData: TagData): Promise<Word.ContentControl> {
  return new Promise<Word.ContentControl>(async (resolve) => {
    // Is called on new contentControl insert
    contentControl.insertHtml(tagData.htmlTable, Word.InsertLocation.replace);
    contentControl.title = tagData.tag;

    const tables = contentControl.tables.load();
    await context.sync();
    const thisTable = tables.getFirst().load();

    await context.sync();
    //postprocessing
    formatTableBorders(thisTable, tagData);
    try {
      contentControl.paragraphs.load('items')
      thisTable.autoFitWindow();
      await context.sync();
    } catch(err) {
      //
    }
    removeParagraphs(contentControl)
    try {
      await context.sync();
    } catch {
      // ignore errors, the api does what we want :)
    }
    removeHiddenColsRows(thisTable, tagData);
    try {
      await context.sync();
    } catch {
      //
    }
    resolve(contentControl);
  });
}

function removeHiddenColsRows(table: Word.Table, tagData: TagData) {
  if (tagData.hiddenRows && tagData.hiddenRows.length > 0) {
    for (const row of tagData.hiddenRows) {
      //console.log('deleting row ' + row);
      table.deleteRows(row);
    }
  }
  if (tagData.hiddenColumns && tagData.hiddenColumns.length > 0) {
    for (const col of tagData.hiddenColumns) {
      //console.log('deleting column ' + col);
      table.deleteColumns(col);
    }
  }
}

function insertTextToContentControlOnContext(context: Word.RequestContext, contentControl: Word.ContentControl, tagData: TagData): Promise<Word.ContentControl> {
  return new Promise<Word.ContentControl>(async (resolve) => {
      contentControl.insertText(`${tagData.tableData[0][0]}`, Word.InsertLocation.replace);
      await context.sync();
      resolve(contentControl);
    });
}

//
export function removeParagraphs(contentControl: Word.ContentControl) {
  const items = contentControl.paragraphs.items;
  if (items.length < 3) return

  if (!items[0].text) items[0].delete()
  const last = items[items.length - 1]
  if (!last.text) {
    last.spaceAfter = 0
    last.lineSpacing = 0.01
  }
}

export function readContentControls(): Promise<Dictionary<Word.ContentControl[]>> {
  const result = new Dictionary<Word.ContentControl[]>();
  return new Promise<Dictionary<Word.ContentControl[]>>((resolve) => {
    Word.run(async (context) => {
      const contentControls = context.document.contentControls.load();
      contentControls.track();
      await context.sync();
      for (let i = 0; i < contentControls.items.length; i++) {
        if (!contentControls.items[i].tag) continue;
        if (result.has(contentControls.items[i].tag)) {
          const cCtrl = result.get(contentControls.items[i].tag);
          cCtrl.push(contentControls.items[i]);
        } else {
          result.set(contentControls.items[i].tag, [contentControls.items[i]]);
        }
      }
      resolve(result);
    });
  });
}

function readContentControlsOnContext(context: Word.RequestContext): Promise<Dictionary<Word.ContentControl[]>> {
  const result = new Dictionary<Word.ContentControl[]>();
  return new Promise<Dictionary<Word.ContentControl[]>>(async (resolve) => {
    const contentControls = context.document.contentControls.load();
    contentControls.track();
    await context.sync();
    for (let i = 0; i < contentControls.items.length; i++) {
      if (!contentControls.items[i].tag) continue;
      if (result.has(contentControls.items[i].tag)) {
        const cCtrl = result.get(contentControls.items[i].tag);
        cCtrl.push(contentControls.items[i]);
      } else {
        result.set(contentControls.items[i].tag, [contentControls.items[i]]);
      }
    }
    console.log(result);
    resolve(result);
  });
}

