/* eslint-disable no-async-promise-executor */
import { Injectable } from "@angular/core";
import { Dictionary } from "@microsoft/office-js-helpers";
import { from, Observable, Subject } from "rxjs";
import { SubSink } from "subsink";
import { getTagData } from "../helpers/helpers.xlsx";
import { clearAndInsertContent, insertContentControlTagAtCursor, readContentControls } from "../helpers/helpers.word";
import { Marker } from "../models/marker";
import { TagData } from "../models/tagdata";
import { ExcelFileService } from "./excel.file.service";
import { MarkerService } from "./marker.service";

@Injectable({
  providedIn: "root",
})
export class WordDocumentService {

  tagContentControls: Dictionary<Word.ContentControl[]> = new Dictionary<Word.ContentControl[]>();
  public updateContentControl: Subject<void> = new Subject<void>();
  subs: SubSink = new SubSink();

  constructor(
    private excelFileService: ExcelFileService,
    private markerService: MarkerService
    ) {
    //On UpdateEvent, read ContentControls from document
    this.subs.sink = this.updateContentControl.subscribe(() => {
      this.readContentControlsFromDocument();
    });
    //On function call readContentControlsFromDocument, save it to our cache
    this.subs.sink = this.readContentControlsFromDocument().subscribe((contentControls) => {
      this.tagContentControls = contentControls;
    });

  }

  readContentControlsFromDocument(): Observable<Dictionary<Word.ContentControl[]>> {
    return from(readContentControls());
  }


  insertTag(tag: string): Observable<Word.ContentControl> {
    const marker: Marker = this.markerService.getMarker(tag);
    const workBook = this.excelFileService.findWorkbook(marker);
    const tagData: TagData = getTagData(marker, workBook);

    if (!tagData) throw new Error("Tagdaten ungültig");
    return from(
      // eslint-disable-next-line no-async-promise-executor
      new Promise<Word.ContentControl>(async (resolve) => {
        const contentControl = await insertContentControlTagAtCursor(tagData);
        this.updateContentControl.next();
        resolve(contentControl);
      })
    );
  }

  updateTags(tags: string[]): Observable<Dictionary<Word.ContentControl[]>> {
    const result = new Dictionary<Word.ContentControl[]> ();
    return from(
      new Promise<Dictionary<Word.ContentControl[]>>(async (resolve) => {
        for (let i = 0; i < tags.length; i++) {
          try {
            const marker = this.markerService.getMarker(tags[i]);
            const workBook = this.excelFileService.findWorkbook(marker);
            const tagData: TagData = getTagData(marker, workBook);
            const tagCCtrl = await clearAndInsertContent(tagData);
            result.set(tags[i], tagCCtrl);
          } catch (error) {
            console.log("Fehler beim updaten des tags: " + tags[i], error);
            result.set(tags[i], [])
          }
        }
        resolve(result);
      })
    );
  }

  updateTag(tag: string): Observable<Word.ContentControl[]> {
    const marker = this.markerService.getMarker(tag);
    const workBook = this.excelFileService.findWorkbook(marker);
    const tagData: TagData = getTagData(marker, workBook);
    if (!tagData) throw new Error("Tagdaten ungültig");
    return from(
      new Promise<Word.ContentControl[]>(async (resolve) => {
        const result = await clearAndInsertContent(tagData);
        resolve(result);
      })
    );
  }
}
