<div *ngIf="markers.length>0">
  <button class="buttons" (click)="replaceAllTags()">Auswahl ersetzen</button>
</div>
<br />
<div *ngIf="markers.length > 0">
    <h3>Definitionen</h3>
    <table bordered="true">
        <thead>
            <tr scope="row">
                <strong>Alle markieren</strong><input type="checkbox" [(ngModel)]="masterSelected" name="list_name" value="m1" (change)="checkUncheckAll()" />
                <th>Platzhalter</th>
                <th></th>
                <th></th>
                <th>Blatt</th>
                <th>Bereich</th>
                <th>Dateiname</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let marker of markers" [class.selectedMarker]="marker === selectedMarker" type="button" (click)="onSelect(marker)">
                <td> <input type="checkbox" [(ngModel)]="marker.replace" name="list_name" value="{{marker.replace}}"
                  (change)="isAllSelected()" /></td>
                <td> {{marker.tag}} </td>
                <td> <button type="button" (click)="insertTag(marker)">Einfügen</button></td>
                <td> <button *ngIf="canReplaceTag(marker.tag)" type="button" (click)="replaceTag(marker.tag)">Ersetzen ({{marker.refCount}})</button></td>
                <td> {{marker.sheet}} </td>
                <td> {{marker.range}} </td>
                <td> {{marker.path}} </td>
            </tr>
        </tbody>
    </table>
    <button class="buttons" (click)="replaceAllTags()">Auswahl ersetzen</button>
</div>

