import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";

/* global Office, console */
export interface IMessageService {
  show(topic: string, message: string): void;
  error(topic: string, message: string): void;
  warn(topic: string, message: string): void;
}

@Injectable({
  providedIn: "root",
})
export class ToastService implements IMessageService {
  constructor(public messageService: ToastrService) {}
  public show(topic: string, message: string) {
    this.messageService.success(message, topic);
  }

  public error(topic: string, message: string): void {
    this.messageService.error(message, topic);
  }

  public warn(topic: string, message: string): void {
    this.messageService.show(message, topic);
  }
}
