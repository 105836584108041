import { Injectable } from "@angular/core";
import { Dictionary } from "@microsoft/office-js-helpers";
import { Subject } from "rxjs";
import { ExcelFile } from "../models/excel.file";
import { Marker } from "../models/marker";
/* global console */

@Injectable({
  providedIn: "root",
})
export class MarkerService {
  markers: Dictionary<Marker> = new Dictionary<Marker>();
  markerUpdate = new Subject<string>();

  getMarkers(): Marker[] {
    return this.markers.values();
  }

  getMarker(tag: string): Marker {
    return this.markers.get(tag);
  }
  removeMarker(tag: string): void {
    const marker = this.markers.get(tag);
    this.markers.delete(tag);
    this.markerUpdate.next(marker.path);
  }

  updateExcelMarkers(excelFiles: Array<ExcelFile>): void {
    if (excelFiles.length === 0) return;
    for (let i = 0; i < excelFiles.length; i++) {
      const file = excelFiles[i];
      for (let j = 0; j < file.markers.length; j++) {
        const marker = file.markers[j];
        this.markers.set(marker.tag, marker);
      }
      this.markerUpdate.next(file.id);
    }
  }

  setMarker(marker: Marker): void {
    this.markers.set(marker.tag, marker);
    this.markerUpdate.next(marker.path);
  }
}
