import {Component, ElementRef, OnDestroy, OnInit, ViewChild, NgZone, AfterViewInit} from "@angular/core";
import { SubSink } from "subsink";
import { Subject } from "rxjs";
import { UserDetail } from "../../models/user.detail";
import { ToastService } from "../../services/toast.service";
import { ToastContainerDirective } from "ngx-toastr";
import { UserService } from "../../services/user.service";

@Component({
  selector: "app-home",
  templateUrl: "app.component.html",
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  titleMessage = "Office Sync Tool";
  subs = new SubSink();
  currentUser = new Subject<UserDetail>();
  displayName: string;
  headerHeight: number;

  @ViewChild('header') header: ElementRef;
  @ViewChild(ToastContainerDirective, { static: true })

  toastContainer!: ToastContainerDirective;
  isVerified = false;
  helpText = "Ihre Lizenz wird verifiziert..."
  private headerResizeObserver: ResizeObserver;

  constructor(
    private messageService: ToastService,
    private userService: UserService,
    private ngZone: NgZone,
  ) {
    //registerLocaleData(localeDe, 'de-DE', localeDeExtra);
  }

  ngOnInit(): void {
    this.subs.sink = this.currentUser.subscribe((user) => {
      if (user.id) {
        this.displayName = user.displayName;
        this.subs.sink = this.userService.verifyUser().subscribe((verified) => {
          this.isVerified = verified;
          //console.log("user", user);
          if (verified && this.userService.user.expiry) {
            const timeTillExpiry = this.userService.user.expiry.valueOf() - Date.now().valueOf();
            const daysTillExpiry = Math.ceil(timeTillExpiry / (1000 * 60 * 60 * 24));
            if (daysTillExpiry <= 14) this.warnOfExpiry(daysTillExpiry);
          }
        });
      }
    })
    this.subs.sink = this.userService.authenticate().subscribe((user) => {
      //console.log("new user", user);
      this.currentUser.next(user);
    }, err => {
      console.log(err);
    });
    this.messageService.messageService.overlayContainer = this.toastContainer;
  }

  ngAfterViewInit() {
    this.headerResizeObserver = new ResizeObserver((entries) => {
      this.ngZone.runOutsideAngular(() => {
        this.headerHeight = (entries[0].target as HTMLElement).offsetHeight;
        this.ngZone.run(() => {}); // Trigger change detection
      });
    });

    this.headerResizeObserver.observe(this.header.nativeElement);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    if (this.headerResizeObserver) {
      this.headerResizeObserver.disconnect();
    }
  }
  warnOfExpiry(days: number) {
    this.messageService.warn("Auslauf Ihrer Lizenz", `Ihre Office Sync Tool Lizenz verfällt in ${days} Tag(en)`);
  }
}
