/* eslint-disable no-async-promise-executor */
import { from, Observable } from "rxjs";
import * as browserFsAccess from "browser-fs-access";

/* global document, console, window */

export function selectFile(contentType: string[] = [".xlsx"], multiple = true): Observable<browserFsAccess.FileWithHandle[]> {
  return from(
    new Promise<browserFsAccess.FileWithHandle[]>(async function (resolve) {
      const options = {
        extensions: contentType,
        multiple: multiple
      }
      console.log('is_supported: ' + browserFsAccess.supported)
      if (multiple) {
        const blobs = (await browserFsAccess.fileOpen(options)) as browserFsAccess.FileWithHandle[];
        const files: browserFsAccess.FileWithHandle[] = [];
        for (let i = 0; i < blobs.length; i++) {
          const handle = blobs[i];
          if (handle) {
            files.push(handle);
          }
        }
        resolve(files);
      } else {
        const blob = (await browserFsAccess.fileOpen(options)) as browserFsAccess.FileWithHandle;
        if (blob) {
          resolve([blob]);
        }
      }
    })
  );
}

export function readFilesFromPath(): Observable<any[]> {
  const options: any = {
    startIn: "documents",
    extensions: [".xlsx"],
  };
  return from(
    new Promise<any[]>(async (resolve) => {
      const files = await browserFsAccess.directoryOpen(options);
      resolve(files);
    })
  );
}

export function toBase64(file: File): Observable<any> {
  return from(
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => resolve(arrayBufferToBase64String(reader.result));
      reader.onerror = (error) => reject(error);
    })
  );
}

export function arrayBufferToBase64String(arrayBuffer: string | ArrayBuffer | null): string {
  const bytes = Array.from(new Uint8Array(arrayBuffer as ArrayBufferLike));
  // eslint-disable-next-line no-undef
  const base64StringFile = btoa(bytes.map((item) => String.fromCharCode(item)).join(""));
  return base64StringFile;
}


