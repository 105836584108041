import * as XLSX from "@sheet/coredemo";
import * as SSF from "SSF"
import {TagData} from "../models/tagdata";
/* global Word, console */

export function formatToGerman(value: string): string {
    const replaceae = String.fromCharCode(195, 164);
    value = value.split(replaceae).join("ä");
    const replaceue = String.fromCharCode(195, 188);
    value = value.split(replaceue).join("ü");
    const replaceoe = String.fromCharCode(195, 182);
    value = value.split(replaceoe).join("ö");
    const replaceAe = String.fromCharCode(195, 132);
    value = value.split(replaceAe).join("Ä");
    const replaceUe = String.fromCharCode(195, 156);
    value = value.split(replaceUe).join("Ü");
    const replaceOe = String.fromCharCode(195, 150);
    value = value.split(replaceOe).join("Ö");
    return value;
}
export function formatTableBorders(table: Word.Table, tagData: TagData): void {
    for (let row = 0; row < tagData.tableBorderStyle[0].length; row++) {
        for (let col = 0; col < tagData.tableBorderStyle.length; col++) {
            if (table.values[row].length <= col) 
                continue;
            

            try {
                const borderStyle = tagData.tableBorderStyle[col][row];
                const cell: Word.TableCell = table.getCell(row, col);
                if (! cell) 
                    continue;
                

                const leftCell = tagData.getBorder(row, col - 1);

                if (borderStyle.left === undefined && (leftCell === undefined || leftCell.right === undefined)) {
                    const left = cell.getBorder(Word.BorderLocation.left);
                    left.type = Word.BorderType.none;
                }
                const topCell = tagData.getBorder(row - 1, col);
                if (borderStyle.top === undefined && (topCell === undefined || topCell.bottom === undefined)) {
                    const top = cell.getBorder(Word.BorderLocation.top);
                    top.type = Word.BorderType.none;
                }
                const rightCell = tagData.getBorder(row, col + 1);
                if (borderStyle.right === undefined && (rightCell === undefined || rightCell.left === undefined)) {
                    const right = cell.getBorder(Word.BorderLocation.right);
                    right.type = Word.BorderType.none;
                }
                const bottomCell = tagData.getBorder(row + 1, col);
                if (borderStyle.bottom === undefined && (bottomCell === undefined || bottomCell.top === undefined)) {
                    const bottom = cell.getBorder(Word.BorderLocation.bottom);
                    bottom.type = Word.BorderType.none;
                }

            } catch (err) { // console.log("error setting borderstyle r, c ", err, borderStyle);
                continue;
            }
        }
    }
}

export function formatNumbers(worksheet: XLSX.WorkSheet) {
    const range = XLSX.utils.decode_range(worksheet['!ref'] as string)
    for (let row = range.s.r; row <= range.e.r; row++) {
        for (let col = range.s.c; col <= range.e.c; col++) {
            const ref = XLSX.utils.encode_cell({r: row, c: col});
            try {
                if (worksheet[ref] && worksheet[ref].z && styleDict[(worksheet[ref].z)]) {
                    worksheet[ref].z = styleDict[worksheet[ref].z];
                    if (worksheet[ref].t === 'd') {
                        formatDate(worksheet, ref);
                    } else {
                        formatNumber(worksheet, ref);
                    }
                }
                // console.log('done formatting!', worksheet[ref]);
            } catch (err) {
                console.log("err on " + JSON.stringify(range), err);
            }
        }
    }
}

function formatDate(worksheet: XLSX.WorkSheet, ref: string): void {
    if (worksheet[ref].z.includes('mmm')) {
        const options = {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
            timeZone: 'Europe/Berlin'
        } as const;
        worksheet[ref].w = worksheet[ref].v.toLocaleString('de-DE', options);
        // console.log('formatting date', worksheet[ref]);
    } else {
        let options = {};
        if (!(worksheet[ref].z as string).includes('h:m')) {
            options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                timeZone: 'Europe/Berlin'
            }
        }
        worksheet[ref].w = worksheet[ref].v.toLocaleString('de-DE', options);
    }
}

function formatNumber(worksheet: XLSX.WorkSheet, ref: string) {
    let minFractionDigits = 2;

    if ((worksheet[ref].z as string).includes(',') && !(worksheet[ref].z as string).includes('€')) {
        const commaSplit = (worksheet[ref].z as string).split(',');
        minFractionDigits = commaSplit[commaSplit.length - 1].length;
        if (commaSplit[commaSplit.length - 1].endsWith('%')) {
            minFractionDigits -= 1;
        }
        if (commaSplit[commaSplit.length - 1].endsWith('"m²"')) {
            minFractionDigits = 2;
        }
    } else {
        if ((worksheet[ref].z as string).includes('€') && (worksheet[ref].z as string).includes(',')) {
            minFractionDigits = 2;
        } else {
            minFractionDigits = 0;
        }
    }
    if ((worksheet[ref].z as string).startsWith('"= "')) {
        const tmpZ = (worksheet[ref].z as string).replace('"= "', '');
        const parsedFloat = parseFloat(SSF.format(tmpZ, worksheet[ref].v));
        worksheet[ref].w = new Intl.NumberFormat('de-DE', {
            'minimumFractionDigits': minFractionDigits,
            'maximumFractionDigits': minFractionDigits
        }).format(parsedFloat);
        worksheet[ref].w = "= " + worksheet[ref].w;
    } else {
        const parsedFloat = parseFloat(SSF.format(worksheet[ref].z, worksheet[ref].v));
        worksheet[ref].w = new Intl.NumberFormat('de-DE', {
            'minimumFractionDigits': minFractionDigits,
            'maximumFractionDigits': minFractionDigits
        }).format(parsedFloat);
    }

    if ((worksheet[ref].z as string).includes('%')) {
        worksheet[ref].w += " %";
    }
    if ((worksheet[ref].z as string).includes('"m²"')) {
        worksheet[ref].w += " m²";
    }
    if ((worksheet[ref].z as string).includes('"€"') || (worksheet[ref].z as string).includes('[$€-407]')) {
        worksheet[ref].w += " €";
    }
    if ((worksheet[ref].z as string).includes('"RBF"')) {
        worksheet[ref].w += " RBF";
    }
    if ((worksheet[ref].z as string).includes('"Jahre"')) {
        worksheet[ref].w += " Jahre";
    }
    if ((worksheet[ref].z as string).includes('"€/m²"')) {
        worksheet[ref].w += " €/m²";
    }
    if ((worksheet[ref].z as string).includes('"€/m² BGF"')) {
        worksheet[ref].w += " €/m² BGF";
    }
}


const styleDict: any = {
    'm/d/yy': 'd.m.yy',
    'dd/mm/yy;@': 'dd.mm.yy;@',
    'd-mmm-yy': '[$-407]d/\\ mmmm\\ yy;@',
    'mmm-yy': 'mmm yy',
    'h:mm AM/PM': 'hh:mm',
    'h:mm:ss AM/PM': 'hh:mm:ss',
    'm/d/yy h:mm': 'd.m.yy hh:mm',
    '[$-de-DE]TT.MMMM JJJJ;@': '[$-407]d/\\ mmmm\\ yyyy;@',
    '[$-407]d/\\ mmmm\\ yyyy;@': '[$-407]d/\\ mmmm\\ yyyy;@',
    '[$-407]dd/\\ mmmm\\ yyyy;@': '[$-407]dd/\\ mmmm\\ yyyy;@',
    "0.00": '#.##0,00',
    '0,00': '#.##0,00',
    '0.0000': '#.##0,0000',
    '0.00000': '#.##0,00000',
    '0.0': '#.##0,0',
    '#.##0 ;(#.##0)': '#,##0 ;(#,##0)',
    '#.##0 ;[Red](#.##0)': '',
    '0.0%': '#.##0,0%',
    '0.00%': '#.##0,00%',
    '0,00%': '#.##0,00%',
    '#,##0.0000_ ;[Red]\\-#,##0.0000\\ ': '#.##0,0000_ ;[Red]\\-#.##0,0000',
    '|0,000|': '|0.000|',
    '0,000': '#.##0,000',
    '|0,00|': '|0,00|',
    '#,##0': '#.##0',
    '#,##0.0': '#.##0,0',
    '#,##0.00': '#.##0,00',
    '#,##0.00\\ "m²"': '#.##0,00"m²"',
    '0.00E+00': '0,00E+00',
    '#,##0 ;(#,##0)': '#.##0 ;(#.##0)',
    '#,##0 ;[Red](#,##0)': '#.##0 ;[Red](#.##0)',
    '#,##0.00;(#,##0.00)': '#.##0,00;(#.##0,00)',
    '#,##0.00;[Red](#,##0.00)': '#.##0,00;[Red](#.##0,00)',
    '##0.0E+0': '##0,0E+0',
    "#,##0.00_ ;[Red]\\-#,##0.00\\ ": "#.##0,00_ ;[Red]\\-#.##0,00",
    '#,##0\\ "€"': '#.##0\\ "€"',
    '#,##0.00\\ "€"': '#.##0,00"€"',
    '#,##0.00\\ [$€-407];[Red]\\-#,##0.00\\ [$€-407]': '#.##0,00\\ [$€-407];[Red]\\-#.##0,00\\ [$€-407]',
    "#,##0.00\\ \"€\";[Red]\\-#,##0.00\\ \"€\"": '#.##0,00"€";[Red]-#.##0,00"€"',
    '_-* #,##0.00\\ _€_-;\\-* #,##0.00\\ _€_-;_-* "-"?? _€_-;_-@_-': '_-* #.##0,00\\ _€_-;\\-* #.##0,00\\ _€_-;_-* "-"??\\ _€_-;_-@_-',
    '_-* #,##0.00\\ "€"_-;\\-* #,##0.00\\ "€"_-;_-* "-"??\\ "€"_-;_-@_-': '_-* #.##0,00\\ "€"_-;\\-* #.##0,00\\ "€"_-;_-* "-"?? "€"_-;_-@_-',
    "#,##0.000": "#.##0,000",
    '#,##0.0000': '#.##0,0000',
    '#,##0.00000': '#.##0,00000',
    '#,##0.000000': '#.##0,000000',
    '0.000': '#.##0,000',
    '"= "#,##0.00\\ "€"': '"= "#.##0,00\\ "€"',
    '0.00;[Red]0.00': '#.##0,00;[Red]#.##0,00',
    '#,##0.00;[Red]#,##0.00': '#.##0,00;[Red]#.##0,00',
    '#,##0\\ "m²"': '#.##0\\ "m²"',
    '#,##0\\ "€/m² BGF"': '#.##0\\ "€/m² BGF"',
    'General\\ "Jahre"': 'General\\ "Jahre"',
    '_-* #,##0.00\\ [$€-407]_-;\\-* #,##0.00\\ [$€-407]_-;_-* "-"??\\ [$€-407]_-;_-@_-': '_-* #.##0,00\\ [$€-407]_-;\\-* #.##0,00\\ [$€-407]_-;_-* "-"??\\ [$€-407]_-;_-@_-',
    '#,##0.00\\ "€/m²"': '#.##0,00"€/m²"'
}
