import { Component } from '@angular/core';
import { SubSink } from 'subsink';
import { readMarkers, toExcelFile, toWorkbook } from '../../helpers/helpers.xlsx';
import { readFilesFromPath, selectFile } from '../../helpers/helpers.file';
import { ExcelFile } from '../../models/excel.file';
import { ExcelFileService } from '../../services/excel.file.service';
import { MarkerService } from '../../services/marker.service';
import { ToastService } from '../../services/toast.service';
import { FileWithHandle } from 'browser-fs-access';

@Component({
  selector: 'app-excel-file-component',
  templateUrl: './excel-file.component.html',
  styleUrls: ['./excel-file.component.scss']
})
export class ExcelFileComponent {

  subs: SubSink = new SubSink();
  excelFiles: ExcelFile[] = [];
  constructor(
    private excelFileService: ExcelFileService,
    private markerService: MarkerService,
    private messageService: ToastService)
  { }

  openExcelFolder(): void {
    this.subs.sink = readFilesFromPath().subscribe(async (files) => {
      for (const file of files) {
        const excelFile = await toExcelFile(file);
        this.excelFileService.addFile(excelFile);
      }
      this.updateExcelFiles();
    });
  }

  openExcelFiles(): Promise<void> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      this.subs.sink = selectFile().subscribe(async (files: FileWithHandle[]) => {
        for (const file of files) {
          const excelFile = await toExcelFile(file);
          this.excelFileService.addFile(excelFile);
        }
        this.updateExcelFiles();
        resolve();
      });
    });
  }

  updateExcelFiles(): void {
    this.excelFiles = this.excelFileService.getFiles();
    this.markerService.updateExcelMarkers(this.excelFiles);
  }

  updateFileData(): Promise<void> {
    console.log("updating filedata");
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve) => {
      for (const excel of this.excelFileService.getFiles()) {
        try {
          excel.workbook = await toWorkbook(excel.file);
          excel.markers = readMarkers(excel.workbook, excel.id);

        } catch (err: any) {
          this.messageService.error("Fehler beim laden des Workbooks: " + excel.id, err.message);
        }
      }
      this.updateExcelFiles();
      this.messageService.show("Daten aktualisiert", "");
      resolve();
    });
  }

}
