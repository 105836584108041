import { CellBorderStyle } from "./cell.border.style";

export class TagData {
  columns: number;
  rows: number;
  tableData: string[][];
  htmlTable: string;
  tableBorderStyle: CellBorderStyle[][]
  hiddenRows: number[];
  hiddenColumns: number[];
  constructor(public tag: string) {}

  isText(): boolean {
    return this.columns === 1 && this.rows === 1;
  }
  getBorder(row: number, column: number): CellBorderStyle | undefined {
    if (row < 0 || row >= this.rows || column < 0 || column >= this.columns) return undefined;
    return this.tableBorderStyle[column][row];
  }
}
