import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppComponent } from "./components/app.component/app.component";
import { MarkerService } from "./services/marker.service";
import { UserService } from "./services/user.service";
import { ToastService } from "./services/toast.service";
import { HttpClient } from "@angular/common/http";
import { ExcelFileService } from "./services/excel.file.service";
import { ToastrModule, ToastContainerModule } from "ngx-toastr";
import { MarkerComponent } from './components/marker-component/marker.component';
import { ExcelFileComponent } from './components/excel-file-component/excel-file.component';
import { WordDocumentService } from "./services/word.document.service";
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { ButtonsComponent } from './components/buttons-component/buttons.component';

@NgModule({
  declarations: [
    AppComponent,
    MarkerComponent,
    ExcelFileComponent,
    ButtonsComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    CommonModule,
    HttpClientModule,
    ToastrModule.forRoot({ positionClass: "toast-top-right" }),
    ToastContainerModule,
    MatButtonModule,
  ],
  exports: [CommonModule, FormsModule],
  providers: [
    //{ provide: LocationStrategy, useClass: HashLocationStrategy },
    HttpClient,
    MarkerService,
    ExcelFileService,
    ToastService,
    WordDocumentService,
    UserService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
