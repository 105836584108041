<div>
  <table *ngIf="excelFiles.length > 0">
    <thead>
    <tr scope="row">
      <th>Dateinamen</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let excelFile of excelFiles">
      <td> {{excelFile.id}} </td>
    </tr>
    </tbody>
  </table>
  <div style="display: flex; gap: 10px;">
    <button (click)="updateFileData()" *ngIf="excelFiles.length > 0" color="primary" mat-raised-button>Daten
      aktualisieren
    </button>
    <button (click)="openExcelFiles()" color="accent" mat-raised-button>Dateien auswählen</button>
  </div>
</div>
