/* eslint-disable no-async-promise-executor */
import * as XLSX from "@sheet/coredemo";
import {ExcelFile} from "../models/excel.file";
import {formatNumbers, formatToGerman} from "./helpers.format";
import {Marker} from "../models/marker";
import {TagData} from "../models/tagdata";
import {FileWithHandle} from "browser-fs-access";
import {CellBorderStyle} from "../models/cell.border.style";

export const SplitRangeSign = ":";

export function toWorkbook(file: FileWithHandle): Promise<XLSX.WorkBook> {
    return new Promise<XLSX.WorkBook>(async (resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const data = new Uint8Array(reader.result as ArrayBuffer);
            const arr = [];

            for (let i = 0; i != data.length; ++ i) {
                arr[i] = String.fromCharCode(data[i]);
            }

            const bstr = arr.join("");
            const workbook: any = XLSX.read(bstr, {
                type: "binary",
                bookVBA: true,
                cellHTML: true,
                cellStyles: true,
                cellNF: true,
                cellDates: true
            });

            console.log('workbook', workbook.SSF);
            resolve(workbook);
        };
        reader.onerror = (error) => {
            console.log("error in fileReader", error);
            reject(error);
        }
        const fileHandle = await file.handle?.getFile();

        if (fileHandle) {
          reader.readAsArrayBuffer(fileHandle as Blob);
        } else {
          // Works on Mac, but will not allow for data change in Excel without
          reader.readAsArrayBuffer(file as Blob);
        }
    });
}

export function toExcelFile(file: FileWithHandle): Promise<ExcelFile> {
    const excelFile: ExcelFile = new ExcelFile(file);
    return new Promise(async (resolve) => {
        excelFile.workbook = await toWorkbook(file);
        excelFile.id = file.name;
        excelFile.markers = readMarkers(excelFile.workbook, file.name);
        resolve(excelFile);
    });
}

export function readMarkers(workbook: XLSX.WorkBook, path = ""): Marker[] {
    const result: Marker[] = [];
    const wb: any = workbook;
    for (let i = 0; i < wb.Workbook.Names.length; i++) {
        if (wb.Workbook.Names[i].Ref === "#NAME?")
            continue;

        const marker: Marker = new Marker();
        const wbNameEntry = wb.Workbook.Names[i];
        marker.tag = formatToGerman(wbNameEntry.Name);
        const locationSplit = wbNameEntry.Ref.split("!");
        if (locationSplit[0] === "**MISSING**") {
            throw new Error("Excelsheet nicht erkannt!");
        }

        marker.sheet = locationSplit[0].split("'").join("");
        marker.sheet = formatToGerman(marker.sheet);
        marker.range = locationSplit[1];
        marker.range = marker.range.split("$").join("");
        marker.path = path;
        if (marker.range.split(":").length === 1)
            marker.range = `${
                marker.range
            }:${
                marker.range
            }`;


        result.push(marker);
    }

    return result;
}


export function getTagData(marker: Marker, workBook: XLSX.WorkBook | undefined): TagData {
    const result = new TagData(marker["tag"]);
    if (workBook === undefined)
        throw new Error("Arbeitsmappe nicht geladen")



    const markedSheet = workBook.Sheets[marker["sheet"]];
    const dimColRow = getRangeDimensions(marker["range"]);
    if (dimColRow.length !== 2)
        throw new Error("Bereich fehlerhaft: " + marker["range"]);

    if (markedSheet === undefined)
        throw new Error("Blatt nicht gefunden: " + marker["sheet"]);


    formatNumbers(markedSheet);

    const tableData = sheetRangeToArray(marker["range"], markedSheet);
    result.tableData = tableData;
    result.columns = dimColRow[0];
    result.rows = dimColRow[1];


    result.htmlTable = sheetRangeToHtml(workBook, marker["sheet"], marker["range"], true);
    const ws: XLSX.WorkSheet = workBook.Sheets[marker["sheet"]];
    ws["!Ref"] = marker["range"];
    // formatNumbers(ws);
    result.tableBorderStyle = getTableBorderStyle(ws);
    addHiddenColsRowsToTagData(ws, result);
    // console.log('tagData generated', result);
    return result;
}

function addHiddenColsRowsToTagData(workSheet: XLSX.WorkSheet, tagData: TagData) {
    if (! workSheet['!ref'])
        return;

    const defRange: string = workSheet['!ref'];
    const tableRange = XLSX.utils.decode_range(defRange)
    const hiddenRows: number[] = []
    const hiddenColumns: number[] = []
    if (workSheet["!rows"] && workSheet["!cols"]) {
        for (let row = 0; row < workSheet["!rows"].length; row++) {
            if (workSheet["!rows"][row] && workSheet["!rows"][row].hidden) {
                hiddenRows.push(row - tableRange.s.r);
            }
        }
        for (let col = 0; col < workSheet["!cols"].length; col++) {
            if (workSheet["!cols"][col] && workSheet["!cols"][col].hidden) {
                hiddenColumns.push(col - tableRange.s.c);
            }
        }
    }
    tagData.hiddenRows = hiddenRows;
    tagData.hiddenColumns = hiddenColumns;
}

function getTableBorderStyle(sheet: XLSX.WorkSheet): CellBorderStyle[][] {
    const result: CellBorderStyle[][] = [];
    const range = XLSX.utils.decode_range(sheet["!ref"] as string);
    for (let col = range.s.c; col < range.e.c + 1; col++) {
        const rows: CellBorderStyle[] = []
        for (let row = range.s.r; row < range.e.r + 1; row++) {
            const cellAddress = `${
                XLSX.utils.encode_col(col)
            }${
                XLSX.utils.encode_row(row)
            }`;
            try {
                const cell: XLSX.CellObject = sheet[cellAddress];
                const borderStyle = new CellBorderStyle(cellAddress);
                borderStyle.bottom = cell.s ?. bottom ?. style as string | undefined;
                borderStyle.top = cell.s ?. top ?. style as string | undefined;
                borderStyle.left = cell.s ?. left ?. style as string | undefined;
                borderStyle.right = cell.s ?. right ?. style as string | undefined;
                rows.push(borderStyle);
            } catch (error) {
                console.log("error reading borderstyle cell: " + cellAddress, error);
                const errCellBorderStyle = new CellBorderStyle(cellAddress);
                rows.push(errCellBorderStyle);
            }
        }
        result.push(rows);
    }
    return result;
}

// /
// /returns [distCol, distRow]
export function getRangeDimensions(range: string): [number, number] {
    const decodedRange = XLSX.utils.decode_range(range);
    const distRow = decodedRange.e.r - decodedRange.s.r + 1;
    const distCol = decodedRange.e.c - decodedRange.s.c + 1;
    console.log(distRow, distCol);
    return [distCol, distRow];

}

function sheetRangeToArray(range: string, workSheet: XLSX.WorkSheet): string[][] {
    workSheet['!ref'] = range;
    const form = XLSX.utils.sheet_to_json(workSheet, {
        raw: true,
        range: range,
        header: 1
    });
    const result: string[][] = toArrayArray(form);
    const rangeCR = XLSX.utils.decode_range(workSheet['!ref'] as string)
    const ref = XLSX.utils.encode_cell({r: rangeCR.s.r, c: rangeCR.s.c});
    if (workSheet[ref].t === 'n' || workSheet[ref].t === 'd') {
        result[0][0] = workSheet[ref].w
    }
    console.log('sheetArangeArray', result);
    if (result.length === 0) {
        const daem: string[] = ['']
        result.push(daem);
    }
    return result;
}

function sheetRangeToHtml(workbook: XLSX.WorkBook, workSheet: string, range: string, editable = false): string {
    const sheet = workbook.Sheets[workSheet];
    sheet['!ref'] = range;
    let result = XLSX.utils.sheet_to_html(sheet, {editable: editable});
    result = result.replace('<head><meta charset="utf-8"/><title>SheetJS Table Export</title></head><body>', '');
    result = result.replace('</body>', '');
    return result;
}


function toArrayArray(output: any[]): string[][] {
    const result: string[][] = [];
    const lengths = output.map((a) => a.length);
    const maxLength = Math.max(... lengths);
    for (let i = 0; i < output.length; i++) {
        const row = toStringArray(maxLength, output[i]);
        if (row.some((el) => el !== "")) {
            result.push(row);
        }
    }
    return result;
}

function toStringArray(length: number, array: any[]): string[] {
    const result: Array<string> = new Array<string>(length);
    for (let i = 0; i < length; i++) {
        if (array[i] === undefined) {
            result[i] = "";
        } else {
            result[i] = array[i];
        }
    }
    return result;
}
