import { Injectable } from "@angular/core";
import { Authenticator, DefaultEndpoints, Utilities } from "@microsoft/office-js-helpers";
import { from, Observable } from "rxjs";
import axios from "axios";
import { AxiosResponse } from "axios"
import { UserDetail } from "../models/user.detail";
import { UserVerificationResponse } from "../models/user.verication.response";
import { appConfig } from "../models/app.config";

/* global console */
@Injectable({
  providedIn: "root",
})
export class UserService {
  authenticator: Authenticator;
  user: UserDetail;
  constructor() {
    this.authenticator = new Authenticator();
    this.authenticator
        .endpoints.registerMicrosoftAuth(appConfig.clientId);
  }

  authenticate(): Observable<UserDetail> {
    return from(
      // eslint-disable-next-line no-async-promise-executor
      new Promise<UserDetail>(async (resolve, reject) => {

        if(Authenticator.isAuthDialog()) {
          console.log("Auth in Dialog, reject");
          reject();
        }

        const auth = await this.authenticator.authenticate(DefaultEndpoints.Microsoft).catch((error: any) => {
          Utilities.log(error);
          reject();
        });
        if (!auth) reject(new Error("Fehler bei der Authentifizierung"));
        const activeToken = this.authenticator.tokens.get("Microsoft");
        if (!activeToken.access_token) reject("no access token found");
        const userDetail = await this.fetchUserInfo(activeToken.access_token as string).catch((err: any) => {
          console.log("error fetching userinfo", err);
          reject();
        });

        resolve(userDetail as UserDetail);
    }));
  }

  fetchUserInfo(accessToken: string): Promise<UserDetail> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<any>(async (resolve, reject) => {
      const config = { 'Authorization': `Bearer ${accessToken}` };
      const response: AxiosResponse<any> | void = await axios.get<any>(`https://graph.microsoft.com/v1.0/me/`, {
          headers: config
      }).catch((err) => {
        console.log(err);
        reject();
      });
      if (response && response.status === 200 && response.data) {
        const user: UserDetail = new UserDetail();
        user.id = response.data.id;
        user.displayName = response.data.displayName;
        if ((response.data.mail === undefined || response.data.mail === null) && (response.data.userPrincipalName !== undefined)) {
          user.mail = response.data.userPrincipalName;
        } else {
          user.mail = response.data.mail;
        }
        this.user = user;
        resolve(user);
      }
      reject();
    });
  }

  verifyUser(): Observable<boolean> {
    return from(
      // eslint-disable-next-line no-async-promise-executor
      new Promise<boolean> (async (resolve) => {
        const result: void | AxiosResponse<UserVerificationResponse> = await axios.get<UserVerificationResponse>(`https://ost.xp-solutions.net/XPS.OST.Api.php?${this.user.mail}`).catch((err) => {
          console.log("Fehler bei der Benutzerverifizierung", err);
          resolve(false);
        });

        if (result && result.status === 200) {
          this.user.expiry = new Date(result.data.expiry);
          const isVerified = result.data.email.toLowerCase() === this.user.mail.toLowerCase();
          resolve(isVerified);
        }
        resolve(false);
      })
    )
  }

}

