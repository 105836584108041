import {enableProdMode, NgModuleRef} from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { authenticate, verifyUser } from './app/helpers/fallbackauthdialog';
import { environment } from './environments/environment';
if (environment.production) {
  enableProdMode();
}
/*global Office*/
// Reference: https://www.npmjs.com/package/@microsoft/office-js
if (Office !== undefined) {
  Office.initialize = () => {
    Office.onReady()
      .then(async(_info: { host: Office.HostType; platform: Office.PlatformType }) => {
        console.log("Office is ready");

        const sideLoadElem = document.getElementById("sideload-msg");
        const user = await authenticate().catch((err: any) => {
          console.log("Fehler bei der Authentifizierung", err);
          if (sideLoadElem) sideLoadElem.textContent = "Fehler bei der Authentifizierung! Bitte wenden Sie sich an Ihren Administrator.";
          return;
        });
        console.log("user after authenticate", JSON.stringify(user));
        if (!(user)) {
          if (sideLoadElem) sideLoadElem.textContent = "Bitte authentifizieren Sie sich mit ihrem Microsoftaccount!"
          return;
        }
        const verified: boolean = await verifyUser(user);
        if (!verified) {
          if (sideLoadElem) sideLoadElem.textContent = "Sie verfügen über keine gültige Lizenz für das Office Sync Tool!"
          return;
        }
        if(sideLoadElem) sideLoadElem.style.display = "none";
        return await bootStrapAngular();
      })
      .catch((err: any) => {
        console.warn('Angular not bootstrapped. Error: \n', err);
      });
  };

  Office.initialize(0); // Start
} else {
  console.log('Bootstrapping Angular, without Office JS');
  bootStrapAngular();
}

function bootStrapAngular(): Promise<void | NgModuleRef<AppModule>> {
  return platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(error => {
      console.log('Error bootstrapping Office Angular app: ', error);
    });
}

