import { Component, OnInit } from '@angular/core';
import { SubSink } from 'subsink';
import { readMarkers, toWorkbook } from '../../helpers/helpers.xlsx';
import { ExcelFile } from '../../models/excel.file';
import { ExcelFileService } from '../../services/excel.file.service';
import { MarkerService } from '../../services/marker.service';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.scss']
})
export class ButtonsComponent implements OnInit {

  excelFiles: ExcelFile[] = [];
  subs: SubSink = new SubSink();
  constructor(private messageService: ToastService,
    private markerService: MarkerService,
    private excelFileService: ExcelFileService) { }

  ngOnInit(): void {
    this.subs.sink = this.excelFileService.fileUpdate.subscribe(() => {
      this.excelFiles = this.excelFileService.getFiles();
    });
  }
  updateExcelFiles(): void {
    this.excelFiles = this.excelFileService.getFiles();
    this.markerService.updateExcelMarkers(this.excelFiles);
  }

  updateFileData(): Promise<void> {
    console.log("updating filedata");
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve) => {
      for (const excel of this.excelFileService.getFiles()) {
        try {
          excel.workbook = await toWorkbook(excel.file);
          excel.markers = readMarkers(excel.workbook, excel.id);

        } catch (err: any) {
          this.messageService.error("Fehler beim laden des Workbooks: " + excel.id, err.message);
        }
      }
      this.updateExcelFiles();
      this.messageService.show("Daten aktualisiert", "");
      resolve();
    });
  }
}
