import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SubSink } from 'subsink';
import { Marker } from '../../models/marker';
import { ExcelFileService } from '../../services/excel.file.service';
import { MarkerService } from '../../services/marker.service';
import { ToastService } from '../../services/toast.service';
import { WordDocumentService } from '../../services/word.document.service';
import { FileUpdateEvent, UpdateType } from '../../models/file.update.event';
import { Dictionary } from '@microsoft/office-js-helpers';

@Component({
  selector: 'app-marker-component',
  templateUrl: './marker.component.html',
  styleUrls: ['./marker.component.scss']
})
export class MarkerComponent implements OnInit {
  subs = new SubSink();
  selectedMarker: Marker | undefined;
  masterSelected: boolean;
  markers: Marker[] = [];
  validTags: string[] = [];
  @ViewChild("markerForm", { static: false })
  markerForm!: NgForm;

  constructor(private markerService: MarkerService,
    private messageService: ToastService,
    private excelFileService: ExcelFileService,
    public wordDocumentService: WordDocumentService) {  }

  ngOnInit(): void {
    this.subs.sink = this.excelFileService.fileUpdate.subscribe((fileUpdateEvent: FileUpdateEvent) => {
      this.onFileUpdateEvent(fileUpdateEvent);
    });

    this.subs.sink = this.wordDocumentService.updateContentControl.subscribe(() => {
      this.subs.sink = this.wordDocumentService.readContentControlsFromDocument().subscribe((cCtrls) => {
        this.validTags = cCtrls.keys();
        this.updateMarkersRefCount(cCtrls);
        this.markers = [...this.markers];
        //console.log("updating valid tags", this.validTags);
      });
    });
    this.subs.sink = this.markerService.markerUpdate.subscribe((updateFileId) => {
      console.log("updating markers of file: " + updateFileId);
      this.markers = this.markerService.getMarkers();
      this.wordDocumentService.updateContentControl.next();
    });

  }
  updateMarkersRefCount(tagCCtrls: Dictionary<Word.ContentControl[]>) {
    for( let i = 0; i < this.markers.length; i++) {
      const tCtrl = tagCCtrls.get(this.markers[i].tag);
      if (tCtrl) this.markers[i].refCount = tCtrl.length;
      else this.markers[i].refCount = 0;
    }
  }
  isAllSelected() {
    this.masterSelected = this.markers.every(function(marker:Marker) {
        return marker.replace == true;
      })
  }
  checkUncheckAll() {
    if (this.masterSelected) {
      this.markers.forEach((m) => m.replace = true);
    } else {
      this.markers.forEach((m) => m.replace = false);
    }
  }

  onFileUpdateEvent(event: FileUpdateEvent) {
    switch (event.Type) {
      case UpdateType.add:
        break;
      case UpdateType.refresh:
        break;
      case UpdateType.remove:
        break;

    }
  }

  onSelect(marker: Marker): void {
    this.readTags();
    this.selectedMarker = marker;
  }

  canReplaceTag(tag: string): boolean {
    const tagItem = this.validTags.find((t) => t === tag);
    if (tagItem) return true;
    return false;
  }

  replaceAllTags() {
    const tags: string[] = [];
    if (this.markers.length === 0) {
      this.messageService.warn("Ersetzen von Tags nicht möglich!", "Keine Marker geladen.");
      return;
    }

    this.subs.sink = this.wordDocumentService.readContentControlsFromDocument().subscribe((cCtrls) => {
      for ( let i = 0; i < this.markers.length; i++) {
        if (cCtrls.has(this.markers[i].tag) && this.markers[i].replace) {
          tags.push(this.markers[i].tag);
        }
      }
      if (tags.length === 0) {
        this.messageService.warn("Ersetzen von Tags nicht möglich!", "Keine Tags gewählt bzw. im Dokument gefunden.");
        return;
      }

      this.subs.sink = this.wordDocumentService.updateTags(tags).subscribe((cCtrlResults) => {
        const resultTags: string[] = cCtrlResults.keys();
        for (let i = 0; i < resultTags.length; i++) {
          const tagResults = cCtrlResults.get((resultTags[i]));
          if (tagResults && tagResults.length > 0) {
            this.messageService.show("Tagdaten ersetzt!", tagResults.length +" Vorkommen des Tags: " + resultTags[i] + " wurden ersetzt");
          } else {
            this.messageService.warn("Fehler beim Ersetzen des Tags", `Für das Tag: ${resultTags[i]} wurden keine Tagdaten ersetzt`);
          }
        }
      },
      (error) => {
        this.messageService.error("Fehler beim Ersetzen der Tags: " + tags.join(","), error.message);
      });
    });
  }

  insertTag(marker: Marker) {
    try {
      if (Word === undefined) throw new Error("Bitte in Word mittels Manifestdatei laden");
      if (marker === (null || undefined)) throw new Error("Marker nicht definiert");
      this.subs.sink = this.wordDocumentService.insertTag(marker.tag).subscribe(() => {
        this.messageService.show("Tag eingefügt", `Tag ${marker.tag} eingefügt`);
      },
      (error) => {
        throw error;
      })
    } catch (error: any) {
      this.messageService.error("Fehler beim Einfügen des Tags: " + marker.tag, error.message);
    }
  }

  replaceTag(tag: string) {
    try {
      if (Word === undefined) throw new Error("Bitte in Word mittels Manifestdatei laden");
      const marker = this.markers.find((m) => m.tag === tag);
      if (marker === (null || undefined)) throw new Error("Marker nicht definiert");
      this.subs.sink = this.wordDocumentService.updateTag(marker.tag).subscribe((cCtrls) => {
        if (cCtrls && cCtrls.length > 0) {
          this.messageService.show("Tagdaten ersetzt!", cCtrls.length +" Vorkommen des Tags: " + marker.tag + " wurden ersetzt");
        } else {
          this.messageService.warn("Fehler beim Ersetzen des Tags", `Für das Tag: ${tag} wurden keine Tagdaten ersetzt`);
        }
      }, (error) => {
        throw error;
      });
    } catch (error: any) {
      this.messageService.error("Fehler beim Ersetzen des Tags: " + tag, error.message);
    }
  }
  readTags(): void {
    this.wordDocumentService.updateContentControl.next();
  }
}
