export class CellBorderStyle {
  cellAddress: string;
  bottom: string | undefined;
  top: string | undefined;
  right: string | undefined;
  left: string | undefined;

  constructor(cellAddress: string) {
    this.cellAddress = cellAddress;
    this.bottom = undefined;
    this.top = undefined;
    this.right = undefined;
    this.left = undefined;
  }
}
