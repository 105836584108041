import { ExcelFile } from "./excel.file";

export class FileUpdateEvent {
  public Type: UpdateType;
  public ExcelFile: ExcelFile;
  constructor(
    type: UpdateType,
    file: ExcelFile
    ) {
      this.Type = type;
      this.ExcelFile = file;
    }
}

export enum UpdateType {
  add = 1,
  remove = 2,
  refresh = 3,
}
