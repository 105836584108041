//import { IWorkBook } from "ts-xlsx";
import { FileWithHandle } from "browser-fs-access";
import { Marker } from "./marker";

export class ExcelFile {
  id: string;
  file: FileWithHandle;
  workbook: any;
  markers: Marker[];
  constructor(f: any) {
    this.id = f.webkitRelativePath === undefined ? f.name : f.webkitRelativePath;
    this.file = f;
  }
}
